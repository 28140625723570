import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import styled from "styled-components";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { useState } from "react";
import { useConfig } from "../providers/config-provider";

const Button = styled(IonButton)`
  color: gray;
  position: absolute;
  right: 0px;
  top: var(--ion-safe-area-top);
`;

const StyledIonPopover = styled(IonPopover)`
  --backdrop-opacity: 0.8;
`;

const MenuButton = () => {
  const history = useHistory();
  const { isNative } = useConfig();
  const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);

  const goTo = (path: string) => {
    setShowMenu(undefined);
    history.push(path);
  };

  return (
    <>
      <Button fill="clear" onClick={(e) => setShowMenu(e.nativeEvent)}>
        <IonIcon
          slot="icon-only"
          icon={!showMenu ? caretDownOutline : caretUpOutline}
        />
      </Button>
      <StyledIonPopover
        isOpen={!!showMenu}
        event={showMenu}
        onDidDismiss={(e) => setShowMenu(undefined)}
      >
        <IonList lines="none" className="ion-padding-vertical">
          {isNative && (
            <IonItem detail={false} onClick={() => goTo("/controller")}>
              <IonLabel>Controller</IonLabel>
            </IonItem>
          )}
          <IonItem detail={false} onClick={() => goTo("/help")}>
            <IonLabel>Help</IonLabel>
          </IonItem>
          <IonItem detail={false} onClick={() => goTo("/privacy")}>
            <IonLabel>Privacy Policy</IonLabel>
          </IonItem>
          <IonItem detail={false} onClick={() => goTo("/terms")}>
            <IonLabel>Terms & Conditions</IonLabel>
          </IonItem>
        </IonList>
      </StyledIonPopover>
    </>
  );
};

export default MenuButton;
