import styled from "styled-components";

import { useClock } from "../providers/clock-provider";

const Container = styled.div<{ isRunning: boolean }>`
  display: flex;
  color: ${(props) => (props.isRunning ? "green" : "red")};
  font-family: "Digital 7", sans-serif !important;
  --ion-font-family: "Digital 7", sans-serif !important;
`;

const Digit = styled.div<{ width: number; fontSize: number }>`
  text-align: right;
  font-size: ${(props) => props.fontSize + "vh"};
  width: ${(props) => props.width + "vh"}; ;
`;

const Clock = () => {
  const { isRunning, showMinutes, minutes, seconds } = useClock();
  const { innerWidth: width, innerHeight: height } = window;
  const ratio = width / height;
  const minutes0 = minutes % 60;
  const minutes1 = Math.floor(minutes0 / 10);
  const minutes2 = minutes0 % 10;
  const seconds1 = Math.floor(seconds / 10);
  const seconds2 = seconds % 10;
  let size = ratio > 1 ? 120 : (120 / 1.5) * ratio;
  if (showMinutes) {
    size = ratio > 1 ? 80 : (80 / 1.5) * ratio;
  }
  return (
    <Container isRunning={isRunning}>
      {showMinutes && (
        <>
          <Digit fontSize={size / 2} width={size / 4}>
            {minutes1}
          </Digit>
          <Digit fontSize={size / 2} width={size / 4}>
            {minutes2}
          </Digit>
        </>
      )}
      <Digit fontSize={size} width={size / 2}>
        {seconds1}
      </Digit>
      <Digit fontSize={size} width={size / 2}>
        {seconds2}
      </Digit>
    </Container>
  );
};

export default Clock;
