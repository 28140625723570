import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import { StatusBar } from "@capacitor/status-bar";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "./theme/overrides.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { BLEProvider } from "./providers/ble-provider";
import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { ClockProvider } from "./providers/clock-provider";
import { ConfigProvider } from "./providers/config-provider";
import Help from "./pages/Help";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Controller from "./pages/Controller";

setupIonicReact();

const App: React.FC = () => {
  const initialize = async () => {
    if (Capacitor.getPlatform() === "android") {
      StatusBar.setOverlaysWebView({ overlay: true });
    }
    if (["android", "ios"].includes(Capacitor.getPlatform())) {
      StatusBar.hide();
    }
  };
  useEffect(() => {
    initialize();
  }, []);
  return (
    <ConfigProvider>
      <BLEProvider>
        <ClockProvider>
          <IonApp>
            <IonReactRouter>
              <IonRouterOutlet>
                <Route exact path="/home" component={Home} />
                <Route exact path="/controller" component={Controller} />
                <Route exact path="/help" component={Help} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </IonRouterOutlet>
            </IonReactRouter>
          </IonApp>
        </ClockProvider>
      </BLEProvider>
    </ConfigProvider>
  );
};

export default App;
