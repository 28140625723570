import { IonButton, IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useBLE } from "../providers/ble-provider";

const InfoButton = () => {
  const { send } = useBLE();

  const onClick = (event: any) => {
    event.stopPropagation();
    send({ action: "info" });
  };

  return (
    <IonButton fill="clear" style={{ color: "gray" }} onClick={onClick}>
      <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
    </IonButton>
  );
};

export default InfoButton;
