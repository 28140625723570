import { IonButton, IonIcon } from "@ionic/react";
import { timeOutline } from "ionicons/icons";
import { useClock } from "../providers/clock-provider";

const ToggleMinutesButton = () => {
  const { showMinutes, setShowMinutes } = useClock();

  const onClick = (event: any) => {
    event.stopPropagation();
    setShowMinutes(!showMinutes);
  };

  return (
    <IonButton
      fill="clear"
      style={{ color: showMinutes ? "green" : "gray" }}
      onClick={onClick}
    >
      <IonIcon slot="icon-only" icon={timeOutline}></IonIcon>
    </IonButton>
  );
};

export default ToggleMinutesButton;
