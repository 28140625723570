import { IonButton, IonIcon } from "@ionic/react";
import { sunnyOutline, moonOutline } from "ionicons/icons";
import { useConfig } from "../providers/config-provider";

const ToggleThemeButton = () => {
  const { darkMode, toggleDarkMode } = useConfig();

  const onClick = (event: any) => {
    event.stopPropagation();
    toggleDarkMode();
  };

  return (
    <IonButton fill="clear" style={{ color: "gray" }} onClick={onClick}>
      <IonIcon
        slot="icon-only"
        icon={darkMode ? sunnyOutline : moonOutline}
      ></IonIcon>
    </IonButton>
  );
};

export default ToggleThemeButton;
