import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import useDoubleClick from "use-double-click";
import { KeepAwake } from "@capacitor-community/keep-awake";
import Clock from "../components/Clock";
import { useClock } from "../providers/clock-provider";
import ControllerButton from "../components/ControllerButton";
import ToggleMinutesButton from "../components/ToggleMinutesButton";
import ToggleThemeButton from "../components/ToggleThemeButton";
import MenuButton from "../components/MenuButton";
import { useConfig } from "../providers/config-provider";
import emitter from "../utils/emitter";
import InfoButton from "../components/InfoButton";

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const LeftButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: var(--ion-safe-area-bottom);
  left: 0;
`;

const RigthButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: var(--ion-safe-area-bottom);
  right: 0;
`;

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();
  const containerRef = useRef(null);
  const { isNative } = useConfig();
  const { isRunning, start, pause, stop } = useClock();

  const onClick = () => {
    if (isRunning) {
      pause();
    } else {
      start();
    }
  };

  const onDoubleClick = () => {
    stop();
  };

  const onData = (data: any) => {
    switch (data.action) {
      case "info":
        presentAlert({
          header: "Info",
          message: `
            Usb connected: ${data.data.usb}<br>
            Battery: ${data.data.battery}
          `,
          buttons: ["OK"],
        });
        break;
      case "single-click":
        onClick();
        break;
      case "double-click":
        onDoubleClick();
        break;
      default:
        break;
    }
  };

  useDoubleClick({
    onSingleClick: onClick,
    onDoubleClick: onDoubleClick,
    ref: containerRef,
    latency: 250,
  });

  useEffect(() => {
    emitter.on("data", onData);
    return () => {
      emitter.removeListener("data", onData);
    };
  });

  useEffect(() => {
    KeepAwake.keepAwake();
    start();
    return () => {
      KeepAwake.allowSleep();
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage>
      <IonContent ref={containerRef} fullscreen scrollY={false}>
        <Center>
          <Clock />
        </Center>
      </IonContent>
      <MenuButton />
      {isNative && (
        <LeftButtons>
          <ControllerButton />
          <InfoButton />
        </LeftButtons>
      )}
      <RigthButtons>
        <ToggleThemeButton />
        <ToggleMinutesButton />
      </RigthButtons>
    </IonPage>
  );
};

export default Home;
