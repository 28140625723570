import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import {
  gameControllerOutline,
  sunnyOutline,
  timeOutline,
} from "ionicons/icons";
import { useConfig } from "../providers/config-provider";

const Help: React.FC = () => {
  const { isNative } = useConfig();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h3>Actions</h3>
        <IonList lines="none">
          <IonItem>
            <IonLabel>
              <h2>Single tap</h2>
              <p>Pause/Resume the clock</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Double tap</h2>
              <p>Stop and reset the clock</p>
            </IonLabel>
          </IonItem>
        </IonList>
        <h3>Buttons</h3>
        <IonList lines="none">
          {isNative && (
            <IonItem>
              <IonIcon icon={gameControllerOutline} slot="start"></IonIcon>
              <IonLabel>Connect bluetooth controller</IonLabel>
            </IonItem>
          )}
          <IonItem>
            <IonIcon icon={timeOutline} slot="start"></IonIcon>
            <IonLabel>Toggle minutes</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={sunnyOutline} slot="start"></IonIcon>
            <IonLabel>Toggle dark mode</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Help;
