import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from "react";

interface IClock {
  isRunning: boolean;
  start: () => void;
  pause: () => void;
  stop: () => void;
  seconds: number;
  minutes: number;
  showMinutes: boolean;
  setShowMinutes: (value: boolean) => void;
}

const ClockContext = createContext<IClock>({} as IClock);

export const useClock = () => useContext(ClockContext);

export const ClockProvider = ({ children }: { children: ReactNode }) => {
  const [showMinutes, setShowMinutes] = useState(false);
  const isRunning = useRef(false);
  const timer = useRef<any>();
  const timeRef = useRef(0);

  const [, _update] = useState(new Date());
  const update = () => _update(new Date());

  const start = () => {
    isRunning.current = true;
    timer.current = setInterval(() => {
      timeRef.current = timeRef.current + 1;
      update();
    }, 1000);
    update();
  };

  const pause = () => {
    isRunning.current = false;
    clearInterval(timer.current);
    update();
  };

  const stop = () => {
    timeRef.current = 0;
    pause();
  };

  const value = {
    isRunning: isRunning.current,
    start,
    pause,
    stop,
    seconds: timeRef.current % 60,
    minutes: Math.floor(timeRef.current / 60),
    showMinutes,
    setShowMinutes,
  };

  return (
    <ClockContext.Provider value={value}>{children}</ClockContext.Provider>
  );
};
