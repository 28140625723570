import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BleClient } from "@capacitor-community/bluetooth-le";
import emitter from "../utils/emitter";

export const SERVICE_ID = "31142376-7fe4-11ed-a1eb-0242ac120002";
export const CHARACTERISTIC_UUID = "351c6af0-7fe4-11ed-a1eb-0242ac120002";

export const BLE_EVENT_DATA = "ble:data";

interface IBLE {
  device: any;
  scan: any;
  connect: any;
  disconnect: any;
  send: (data: any) => void;
}

const BLEContext = createContext<IBLE>({} as IBLE);

export const useBLE = () => useContext(BLEContext);

export const BLEProvider = ({ children }: { children: ReactNode }) => {
  const reconnect = useRef(false);
  const [device, setDevice] = useState<any>();

  const initialize = async () => {
    await BleClient.initialize();
    const devices = await BleClient.getConnectedDevices([SERVICE_ID]);
    if (devices.length) {
      setDevice(devices[0]);
    }
  };

  const scan = async () => {
    const device = await BleClient.requestDevice({
      services: [SERVICE_ID],
    });
    if (device) {
      connect(device);
    }
  };

  const connect = async (device: any) => {
    reconnect.current = true;
    try {
      await BleClient.connect(
        device.deviceId,
        () => {
          setDevice(undefined);
          if (reconnect.current) {
            connect(device);
          }
        },
        { timeout: 30000 }
      );
      setDevice(device);
    } catch {
      setDevice(undefined);
    }
  };

  const disconnect = async () => {
    reconnect.current = false;
    await BleClient.disconnect(device.deviceId);
  };

  const send = async (data: any) => {
    await BleClient.write(
      device.deviceId,
      SERVICE_ID,
      CHARACTERISTIC_UUID,
      new DataView(new TextEncoder().encode(JSON.stringify(data)).buffer)
    );
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device) {
      BleClient.startNotifications(
        device.deviceId,
        SERVICE_ID,
        CHARACTERISTIC_UUID,
        (value) => {
          emitter.emit("data", JSON.parse(new TextDecoder().decode(value)));
        }
      );
    }
    return () => {
      BleClient.stopEnabledNotifications();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const value = {
    device,
    scan,
    connect,
    disconnect,
    send,
  };

  return <BLEContext.Provider value={value}>{children}</BLEContext.Provider>;
};
