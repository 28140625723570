import { Capacitor } from "@capacitor/core";
import React, { createContext, ReactNode, useContext, useState } from "react";

interface IConfig {
  isNative: boolean;
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const ConfigContext = createContext<IConfig>({} as IConfig);

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [darkMode, setDarkMode] = useState(true);

  const isNative = Capacitor.isNativePlatform();

  const toggleDarkMode = () => {
    document.body.classList.toggle("dark", !darkMode);
    setDarkMode(!darkMode);
  };

  const value = {
    isNative,
    darkMode,
    toggleDarkMode,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
