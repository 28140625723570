import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonFabButton,
  IonIcon,
  IonFab,
  IonProgressBar,
} from "@ionic/react";
import bluetoothle from "cordova-plugin-bluetoothle/www/bluetoothle";
import { useEffect, useRef, useState } from "react";
import { CHARACTERISTIC_UUID, SERVICE_ID } from "../providers/ble-provider";
import { Capacitor } from "@capacitor/core";
import styled from "styled-components";
import {
  gameControllerOutline,
  informationCircleOutline,
  triangleOutline,
} from "ionicons/icons";
import useDoubleClick from "use-double-click";

const Center = styled.div`
  padding: 32px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledIonFab = styled(IonFab)`
  margin-top: -80px;
  margin-left: -80px;
`;

const StyledIonFabButton = styled(IonFabButton)`
  --background: green;
  width: 160px;
  height: 160px;
  ion-icon {
    height: 1.5em;
    width: 1.5em;
  }
`;

const Controller: React.FC = () => {
  const [connected, setConnected] = useState(false);
  const buttonRef = useRef(null);

  const start = async () => {
    const platform = Capacitor.getPlatform();
    bluetoothle.addService(
      (response: any) => {
        console.log("addService -> success", response);
        bluetoothle.startAdvertising(
          (response: any) => {
            console.log("startAdvertising -> success", response);
          },
          (error: any) => {
            console.log("startAdvertising -> error", error);
          },
          {
            service: SERVICE_ID,
            services: [SERVICE_ID],
            name: "Smart Clock - " + platform.toUpperCase(),
            includeDeviceName: false,
          }
        );
      },
      (error: any) => {
        console.log("addService -> error", error);
      },
      {
        service: SERVICE_ID,
        characteristics: [
          {
            uuid: CHARACTERISTIC_UUID,
            properties: {
              read: true,
              writeWithoutResponse: true,
              write: true,
              notify: true,
              indicate: true,
            },
            permissions: {
              read: true,
              write: true,
            },
          },
        ],
      }
    );
  };

  const onClick = async () => {
    bluetoothle.notify(
      (response: any) => {
        console.log("notify -> success", response);
      },
      (error: any) => {
        console.log("notify -> error", error);
      },
      {
        service: SERVICE_ID,
        characteristic: CHARACTERISTIC_UUID,
        value: btoa("1"),
      }
    );
  };

  const onDoubleClick = () => {
    bluetoothle.notify(
      (response: any) => {
        console.log("notify -> success", response);
      },
      (error: any) => {
        console.log("notify -> error", error);
      },
      {
        service: SERVICE_ID,
        characteristic: CHARACTERISTIC_UUID,
        value: btoa("2"),
      }
    );
  };

  useDoubleClick({
    onSingleClick: onClick,
    onDoubleClick: onDoubleClick,
    ref: buttonRef,
    latency: 250,
  });

  useEffect(() => {
    const isAvailable = Capacitor.getPlatform() !== "web";
    if (isAvailable) {
      bluetoothle.initialize();
      bluetoothle.initializePeripheral(
        (response: any) => {
          setConnected(response.status === "subscribed");
          console.log("initializePeripheral -> success", response);
          if (response.status === "enabled") {
            start();
          }
        },
        (error: any) => {
          console.log("initializePeripheral -> error", error);
        },
        {
          request: true,
        }
      );
    }
    return () => {
      if (isAvailable) {
        bluetoothle.stopAdvertising((response: any) => {
          console.log("stopAdvertising -> success", response);
          bluetoothle.removeAllServices((response: any) => {
            console.log("removeAllServices -> success", response);
          });
        });
      }
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {!connected && (
          <>
            <IonProgressBar type="indeterminate" />
            <Center>
              <IonIcon
                style={{ fontSize: 40 }}
                icon={informationCircleOutline}
              />
              <p>Ready to Pair</p>
              <p>You can use this device to control an external Smart Clock</p>
              <p>
                Tap the button
                <IonIcon
                  style={{ fontSize: 20, paddingLeft: 8, paddingRight: 8 }}
                  icon={gameControllerOutline}
                />
                on the Smart Clock
              </p>
            </Center>
          </>
        )}
        <StyledIonFab
          vertical="center"
          horizontal="center"
          slot="fixed"
          style={{ display: connected ? "block" : "none" }}
        >
          <StyledIonFabButton ref={buttonRef}>
            <IonIcon icon={triangleOutline} size="large" />
          </StyledIonFabButton>
        </StyledIonFab>
      </IonContent>
    </IonPage>
  );
};

export default Controller;
