import { IonButton, IonIcon } from "@ionic/react";
import { gameController } from "ionicons/icons";
import { useBLE } from "../providers/ble-provider";

const ControllerButton = () => {
  const { device, scan, disconnect } = useBLE();

  const onClick = (event: any) => {
    event.stopPropagation();
    if (!device) {
      scan();
    } else {
      disconnect();
    }
  };

  return (
    <IonButton
      fill="clear"
      style={{ color: device ? "green" : "gray" }}
      onClick={onClick}
    >
      <IonIcon slot="icon-only" icon={gameController}></IonIcon>
    </IonButton>
  );
};

export default ControllerButton;
